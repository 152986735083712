import React, { Component } from 'react'
// import DownloadImage from '../images/download-image.svg';

export default class DownloadPDF extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            success: false,
            stepCount: 1,
            fields: {},
            errors: {},
            loading: false
        };

        this.contactSubmit = this.contactSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
      }

      handleRedo = () =>{
        this.setState({
            success: false,
            stepCount: 1,
            fields:{
                email: '',
            }
        });
      }

      contactSubmit = e => {
        e.preventDefault();
        // console.log(this.state.services)

        if(this.validateForm()) {
            this.setState({
                loading: true
            });

            setTimeout(() => {
                this.setState({
                    success: true,
                    loading: false,
                    fields:{
                        email: ''
                    }
                });

                // this.handleServiceSelect();
            }, 1000);

            var formdata = new FormData();
            formdata.append("email", this.state.fields.email);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://dev.alkye.com/wp-json/custom/v1/pdf-downlod", requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    success: true,
                    loading: false
                });
            })
            .catch(error => console.log('error', error));
        }
      }

    handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })

		// console.log(e.target.value)
	}

    validateForm() {
        let phoneError = this.state.phoneError;
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
	
		// Email Address error messages
		// if (!fields["email"]) {
		//   formIsValid = false;
		//   errors["email"] = "*Please enter your Email Address.";
        // //   this.setState({
        // //     stepCount: 2
        // //   });
		// }
	
		// if (typeof fields["email"] !== "undefined") {
		//   var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		//   if (!pattern.test(fields["email"])) {
		// 	formIsValid = false;
		// 	errors["email"] = "*Please enter valid Email Address.";
        //     // this.setState({
        //     //     stepCount: 2
        //     // });
		//   }
		// }
        
          const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (!fields["email"] || fields["email"].length === 0) {
                formIsValid = false;
                errors["email"] = "*Please enter your Email Address.";
            }

            if (!regex.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = '*Please enter your valid Email Address.';
            }
	
		this.setState({
            phoneError,
		    errors: errors
		});
		return formIsValid;
	}

      handleKeyDown = (e, count) => {
          if (e.key === 'Enter') {
                // if (this.validateForm()) {
                    this.setState({
                        stepCount: count
                    });
                // }

                // if(count === "success"){
                //     this.contactSubmit();
                // }
        }
      }

  render() {
    //   const pageContent = this.props.data;

    return (
        <div className="pb-8 overflow-hidden" id="download">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-sm-7 col-lg-5 mb-sm-0 mb-4">
                        <h2 className='h1' data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: this.props.title}} />
                        <p data-aos="fade-up" data-aos-delay="400" dangerouslySetInnerHTML={{__html: this.props.desc}} />
                        {this.state.success ? (
                            <div className='contact-form-thankyou pt-2' data-aos="fade-up" data-aos-delay="400">
                                <p>Thank you for submitting your details, you will receive the report in your email shortly. If you haven’t received it, please make sure to check your spam.</p>
                            </div>
                        ):(
                            <form onSubmit={this.contactSubmit} data-aos="fade-up" data-aos-delay="400">
                                <div className='download-form d-flex align-items-center'>
                                    <input id="email" className='form-control' onChange={this.handleChange} value={this.state.fields.email} name="email" onKeyDown={(e) => this.handleKeyDown(e, 3)} placeholder='Enter your email address' type="text" />
                                    <button aria-label="Submit" type='submit' className='btn btn-control'>
                                        {this.state.loading ? 'Loading...' : 'Download'} 
                                    </button>
                                </div>
                                <div className={`invalid-feedback mt-1 ${!this.state.fields.email ? (this.state.errors.email ? 'd-block' : null) : null }`}>{this.state.errors.email}</div>
                            </form>
                        )}
                    </div>
                    <div className="col-sm-5 col-lg-5 text-center" data-aos="fade-up" data-aos-delay="400">
                        <img src={this.props.img} alt="image" className='img-fluid' />
                    </div>
                </div>
            </div>            
        </div>
    )
  }
}
