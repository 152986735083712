import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MakeStrengthAd from "../components/makeStrengthads"
import PartnerLogoAds from "../components/partnerLogoAds"
import ServicesSlider from "../components/ServicesAds"
// import IphoneCell from "../images/heart-handshake.svg"
import DownloadPDF from "../components/DownloadPDF"

const AdLanding = ({data}) => {
  const pageContent = data.allWpPage.nodes[0];
  const pageContentPage = data.allWpPage.nodes[0]?.adlanding;
  
  
  return(
    <Layout>
      <Seo title={pageContent?.seo.title} image="https://dev.alkye.com/wp-content/uploads/2021/12/Sonia-1-1-scaled-1.webp" description={pageContent?.seo.metaDesc} focuskw={pageContent?.seo.focuskw} />    
      
      <div className="page-intro pb-0 page-ad page-inner">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                  <div className="col-sm-12 col-md-7 col-lg-7 col-xl-5">
                      <h1 className="title mb-3 mb-md-4 pb-xl-2" data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: pageContentPage?.bannerHeading}} />
                      
                      <div className="d-flex award-list flex-wrap align-items-center" data-aos="fade-up" data-aos-delay="400">
                        {pageContentPage?.awards?.map((item, index) => (
                          <div className="award-item d-flex align-items-center" key={index}><span dangerouslySetInnerHTML={{__html: item.name}} /></div>
                        ))}
                      </div>
                      <div  data-aos="fade-up" data-aos-delay="600" dangerouslySetInnerHTML={{__html: pageContent?.content}} />
                  </div>
                  <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                    <div className="banner-card d-flex" data-aos="fade1-up" data-aos-delay="200">
                        <div className="banner-card-form m-auto">
                          <img src={'/heart-handshake.svg'} width="48" alt="Thank you" className="mb-2" />
                          <h4 className="mb-3"><big>Thank <br /><em>you for your</em> enquiry</big></h4>
                          <p>We will aim to get in touch with you within 2 business hours.</p>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </div>

        <ServicesSlider />
                
        <MakeStrengthAd title={pageContentPage?.makeDataYourStrengthSection?.title} desc={pageContentPage?.makeDataYourStrengthSection?.description} />

        <PartnerLogoAds />

        <DownloadPDF title={pageContentPage?.digitalTrends?.title} desc={pageContentPage?.digitalTrends?.description} img={pageContentPage?.digitalTrends?.thumbnail?.mediaItemUrl} />
    
    </Layout>
  )
}

export default AdLanding

export const query = graphql`{
    allWpPage(filter: {slug: {eq: "ad-landing"}}) {
      nodes {
        seo {
          title
          metaDesc
          focuskw
        }
        content
        adlanding {
          bannerHeading
          makeDataYourStrengthSection {
            title
            description
          }
          awards {
            name
          }
          digitalTrends {
            description
            title
            thumbnail {
              altText
              mediaItemUrl
            }
          }
        }
      }
    }
  }`